<template>
  <div class="height100">
    <div class="height100">
      <div class="height100">
        <div class="dashboard_right_contents_wrap">
          <div class="dashboard_top_filter_wrap">
            <!--상단 우측 필터 부분-->
            <div class="flex justify-space-between">
              <div
                v-if="!actionMode"
                class="chip_wrap"
              >
                <template>
                  <v-chip
                    v-for="(item, index) in chips"
                    :key="index"
                    class="mr-1"
                    :color="item.color || '#EDD8FB'"
                  >
                    <div
                      style="white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis;"
                    >
                      {{ item.text }}
                    </div>
                  </v-chip>
                </template>
              </div>
              <div class="flex flex_end">
                <div :style="actionMode ? 'width:100%' : ''">
                  <template v-if="actionMode">
                    <ToolbarSearchAction
                      ref="toolbarSearchAction"
                      :value="searchOptions.searchKeyword"
                      :loading="loading"
                      :placeholder="'거래처/주소' | translate"
                      :style="'width:100%;'"
                      always-active
                      @close="actionMode=false"
                      @search="onSearchClicked"
                    />
                  </template>
                  <template v-else>
                    <v-btn
                      icon
                      @click="actionMode = true"
                    >
                      <img src="@/assets/images/main/svg/buttonSearch.svg">
                    </v-btn>
                  </template>
                </div>
                <div
                  v-if="!actionMode"
                  class="filter_wrap flex_end"
                >
                  <div
                    @click="onDialog"
                  >
                    <div class="filter-icon" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 필터 -->
            <work-record-filter-modal
              :search-options.sync="searchOptions"
              :dialog="dialog"
              :staff-options="staffOptions"
              :work-state-code-options="workStateCodeOptions"
              @status-options-items="statusOptionsItems"
              @onDialogClose="onDialog"
            />
          </div>
          <div>
            <!-- 인피니트 리스트 -->
            <vue-infinite-loader
              :items="items"
              :fetch="fetch"
              :identifier="identifier"
              item-key="raw.accountId"
            >
              <template #default="{ item }">
                <work-record-item-card
                  :item="item"
                  @click:item="onDetailClicked"
                />
              </template>
            </vue-infinite-loader>

            <!-- 추가 버튼 -->
            <add-button
              style="position: fixed; left: 10px; bottom: 65px;"
              @click:button="onAddClicked"
            />

            <!-- 새로고침 버튼 -->
            <refresh-button
              :container="mounted ? $refs.container : undefined"
              @click="onRefresh"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {PATH} from "@/constants";
import Account from "@/model/account";
import OnMainFilterTriggered from "@/event/OnMainFilterTriggered";
import AddButton from "@/components/comp/AddButton";
import VuexProps from "@/framework/vuex/VuexProps";
import OnFilterResetListener from "@/framework/listeners/OnFilterResetListener";
import RefreshButton from "@/components/comp/RefreshButton";
import workRecordItemCard from "@/views/home/WorkRecordItemCard";
import OnMainSearchTriggered from "@/event/OnMainSearchTriggered";
import WorkRecordFilterModal from "@/views/home/WorkRecordFilterModal";
import ToolbarSearchAction from "@/components/app/toolbar/ToolbarSearchAction";

export default {
  name: "WorkRecord",
  components: {
    WorkRecordFilterModal,
    workRecordItemCard,
    RefreshButton,
    AddButton,
    ToolbarSearchAction,
  },
  mixins: [
    OnMainSearchTriggered,
    OnMainFilterTriggered,
    VuexProps,
    OnFilterResetListener],
  props: {},
  data() {
    return {
      dialog: false,
      innerDate: this.$toFormat(this.$now(), 'yyyy-MM'),
      menu: false,
      modal: false,
      items: [],
      chips: [],
      searchOptions: {
        sortBy: ['workDate','endTime'],
        sortDesc: [true, true],
        searchKeyword: null,
        start: this.$now().minus({ day: 3 }).startOf('day').toISO(),
        end: this.$now().endOf('day').toISO(),
        workState: null,
        worker: null,
      },
      identifier: 0,
      mounted: false,
      staffOptions: undefined,
      workStateCodeOptions :[
        { codeKey: null, codeValue: this.$translate('전체') },
        { codeKey: 'R', codeValue: this.$translate('작성중') },
        { codeKey: 'Y', codeValue: this.$translate('작성완료') },
      ],
      actionMode: false,
    }
  },
  watch: {
    searchOptions: {
      deep: true,
      handler() {
        this.identifier++
      }
    },
  },
  created() {
    this.loadOptionsEmployee()
    this.statusOptionsItems()
    this.initSearchOptions()
  },
  mounted() {
    this.mounted = true;
    if (this.historyManager.isForward) { // pushState 한 경우만 초기화
      this.identifier++;
    } else if (this.historyManager.isBack) {
      this.loadAllUpToCurrent();
    }
  },
  methods: {
    onSearchClicked(keyword) {
      this.searchOptions.searchKeyword = keyword
    },
    /****
     * 필터 칩스 세팅
     */
    statusOptionsItems() {
      let arr = []

      let dateStartText = null
      let dateEndText = null
      if (!this.$isNOU(this.searchOptions.start)) {
        dateStartText = this.$date(this.searchOptions.start);
      }

      if (!this.$isNOU(this.searchOptions.end)) {
        dateEndText = this.$date(this.searchOptions.end);
      }

      if (!this.$isNOU(dateStartText) || !this.$isNOU(dateEndText)) {
        arr.push({text: (dateStartText || "") + " ~ " + (dateEndText || "")});
      }

      // 관리자일 떄만 작업자 필터 사용 가능.
      if (!this.$isEmpty(this.searchOptions.worker) && this.user.isAdmin) {
        this.staffOptions.filter(e => this.searchOptions.worker === e.userId)
            .map(e => e.userFullname).forEach(e => arr.push({text: e}));
      }

      if (this.searchOptions.workState !== undefined) {
        this.workStateCodeOptions.filter(e => this.searchOptions.workState === e.codeKey)
            .map(e => e.codeValue).forEach(e => arr.push({text: e}));
      }
      this.chips = arr
    },

    /****
     * 작업자 조회
     */
    loadOptionsEmployee() {
      this.$request(PATH.STAFF_EMPLOYEES_LIST)
          .post()
          .enqueue()
          .then((res) => {
            this.staffOptions = res.data
          })
    },

    /****
     * 필터 다이얼로그 닫기
     */
    onDialogClose() {
      this.$emit('onDialogClose')
    },

    onDialog() {
      this.dialog = !this.dialog;
    },

    /**
     * 작업일지 상세 화면 오픈
     * params 는 선택하는 아이템의 조건을 보내준다.
     * @param item
     */
    onDetailClicked(item) {
      const params = {
        worker: item._account.worker,
        workDate: item._account.workDate,
        workerName: this.user.name,
      }

      this.routerManager.push({name: "WorkRecordDetail" , params:{ parentOptions: params }});
    },

    async loadAllUpToCurrent() {
      const identifier = this.identifier;
      const response = await this.$request(PATH.REPORT.WORK_REPORT.LIST)
          .setObject({
            ...this.searchOptions,
            pageNumber: 1,
            pageSize: this.searchOptions.pageNumber * this.searchOptions.pageSize,
          })
          .wait(500)
          .enqueue();
      if (identifier !== this.identifier) return;
      this.items = response.data.map(e => new Account(e));
    },

    async fetch(page, pageSize) {
      this.searchOptions.pageNumber = page;
      this.searchOptions.pageSize = pageSize;

      const response = await this.$request(PATH.REPORT.WORK_REPORT.LIST)
          .setObject(this.searchOptions)
          .catch()
          .wait(500)
          .enqueue();
      return response.data.map(e => new Account(e));
    },

    /**
     * 검색키워드와 필터 조건을 초기화 합니다.
     */
    initSearchOptions() {
      this.initFilterState();
    },

    /**
     * 필터 조건을 초기화 합니다.
     */
    initFilterState() {
      this.searchOptions.worker = this.user.isAdmin ? null : this.user.userId
      this.searchOptions.start = this.$now()
          .minus({ day: 3 })
          .startOf('day')
          .toISO()
      this.searchOptions.end = this.$now().endOf('day').toISO()
      this.searchOptions.searchKeyword = null
    },

    onMainFilterTriggered(state) {
      this.searchOptions = {...this.searchOptions, ...state};
      this.identifier++;
    },

    /**
     * 추가 버튼이 눌러졌습니다.
     */
    onAddClicked() {
      // 새글 추가 할 때에는 접속자 정보를 넘겨준다.
      const params = {
        worker: this.user.userId,
        workDate: this.$now().endOf('day').toISODate(),
        workerName: this.user.name,
      }

      this.routerManager.push({name: "WorkRecordDetail" , params:{ parentOptions: params }});
    },

    /**
     * 필터 조건을 초기화하고 리로드합니다.
     */
    onFilterReset() {
      this.initSearchOptions();
      this.identifier++;
    },

    /**
     * 새로고침 버튼을 클릭하였습니다.
     *
     * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
     */
    onRefresh() {
      this.identifier++;
    },

  }
}
</script>

<style scoped>

.dashboard_top_filter_wrap {
  display: flex;
  padding-bottom: 3px;
}

.date_picker_wrap .v-input {
  margin: 0;
  padding: 0;
}

.date_picker_wrap >>> .v-text-field__details {
  display: none;
}

.date_picker_wrap >>> .v-text-field input {
  padding: 4px;
  background-image: url(/img/dashboard_arrow.svg);
  background-repeat: no-repeat;
  background-position: 92% center;
  background-size: 8px;
  font-size: 0.917rem;
}

.date_picker_wrap >>> .v-text-field__slot {
  background-color: white;
  border: 1px solid #ccc;
  width: 112px;
  height: 34px;
  border-radius: 4px;
  margin-right: 2.8%;
}

.date_picker_wrap >>> .v-input__slot:before {
  display: none;
}

.date_picker_wrap >>> .v-input__slot:after {
  display: none;
}

.filter_wrap {
  display: flex;
  align-items: center;
  /* padding: 2px 8px;
   !*width: 130px;*!
   height: 34px;
   background: #fff;
   border: 1px solid #ccc;
   border-radius: 4px;
   justify-content: space-between;
   margin-bottom: 4px;*/
}

.filter_wrap div {
  width: 35px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}


.filter_wrap div img {
  width: 100%;
}

.dashboard_right_contents_wrap {
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: 12px;
  background: #F8F9FB;
}

.dashboard_right_content {
  display: inline-block;
  vertical-align: top;
  /*height: 572px;*/
  /*min-height: 412px;*/
}

* {
  font-family: "Noto Sans KR", sans-serif;
}

.printer-icon {
  width: 18px;
  height: 18px;
  background-image: url("/img/dashboard/dashboard_print_icon.svg");
}

.printer-icon:hover {
  background-image: url("/img/dashboard/dashboard_print_color_icon.svg");
}

.mail-icon {
  width: 18px;
  height: 18px;
  background-image: url("/img/dashboard/dashboard_mail_icon.svg");
}

.mail-icon:hover {
  background-image: url("/img/dashboard/dashboard_mail_color_icon.svg");
}

.filter-icon {
  width: 18px;
  height: 18px;
  background-image: url("/img/dashboard/dashboard_filter_icon.svg");
  background-size: 26px;
}

.filter-icon:hover {
  background-image: url("/img/dashboard/dashboard_filter_color_icon.svg");
}


.switch {
  font-size: 0.8125rem;
  line-height: 25px;
}

.v-chip.v-size--default {
  border-radius: 14px;
  color: white;
  font-size: 12px;
  height: 28px;
}

.chip_wrap {
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-align: left;
}


.close_button_wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 49px;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  right: 0;
  z-index: 1;
}

.close_button_wrap > img {
  position: absolute;
}

.v-chip.v-size--default {
  color: #AF4DEC;
}

>>>.v-input--switch .v-input--switch__thumb {
  background-color: white !important;
}

>>> .v-input--switch--inset .v-input--switch__track {
  opacity: 1;
}
.status-options-bar {
  position: fixed;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  border-bottom: 1px solid #ddd;
  top: 56px;
  min-height: 49px;
  max-height: 49px;
  left: 0;
  right: 0;
  z-index: 3;
  background: white;
}
</style>