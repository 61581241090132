<template>
  <v-dialog
    v-model="dialog"
    max-width="340px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="overflow-hidden">
      <div class="d-flex justify-space-between align-center px__15">
        <v-card-title
          class="d-flex justify-center flex-grow-1 font__20 font-weight-bold"
        >
          {{ '검색 필터' | translate }}
        </v-card-title>

        <v-btn
          text
          class="button__Close"
          @click="onDialogClose"
        >
          <!--{{ '닫기' | translate }}-->
          <img src="@/assets/images/main/svg/button_Close.svg">
        </v-btn>
      </div>


      <v-card-text
        style="max-height: calc(90vh - 300px)"
        class="overflow-y-auto overflow-x-hidden"
      >
        <v-row
          justify="center"
          class="align-center"
        >
          <!--          <v-col cols="4">-->
          <!--            접수구분-->
          <!--          </v-col>-->
          <v-col cols="12">
            <v-select-backguard
              v-model="internalSearchOptions.accLevelType"
              :items="accLevelOptions"
              item-text="codeValue"
              item-value="codeKey"
              :placeholder="'거래처 등급' | translate"
              hide-details
              clearable
              class="pa-0 ma-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="px-0"
          >
            <j-period-picker
              :start-label="'시작일' | translate"
              :end-label="'종료일' | translate"
              :date-end.sync="internalSearchOptions.endDate"
              :date-start.sync="internalSearchOptions.startDate"
              icon="mdi-calendar"
              class="mx-3"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <!--      <v-divider />-->
      <v-card-actions
        class="ma-0 mt-2 pa-0"
      >
        <!--        <v-spacer />-->
        <v-btn
          text
          class="button__100per radius__None"
          @click="onClickApply"
        >
          {{ '적용' | translate }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import CodeOptions from "@/components/mixins/CodeOptions";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import VSelectBackguard from "@/components/part/VSelectBackguard";
    import JPeriodPicker from "@/assets/plugins/mps-vuetify-compat/pickers/JPeriodPicker.vue";
    import {cloneDeep} from "lodash";

    export default {
        name: "ConsultNoteSearchFilterModal",
        components: {JPeriodPicker, VSelectBackguard},
        mixins: [CodeOptions, ModalVuetifyHelper, VuetifyModalBackGuardInterface],
      props: {
        dialog: Boolean,
        searchOptions: undefined,
        accLevelOptions:undefined,
      },
        data() {
            return {
              internalSearchOptions: null,
            };
        },
        computed: {
        },
      watch: {
        searchOptions: {
          deep: true,
          immediate: true,
          handler(newValue) {
            this.internalSearchOptions = cloneDeep(newValue)
          }
        },
      },

        methods: {

          onDialogClose() {
            this.$emit('onDialogClose')
          },

            onClickApply() {
              this.$emit('update:searchOptions', this.internalSearchOptions)
              this.$emit('status-options-items')
              this.onDialogClose();
            },
        }
    }
</script>


<style scoped>
    >>> .v-dialog {
        overflow: hidden !important;
    }

    >>> .v-select__selections > input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-select__selections > input:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-dialog > .v-card > .v-card__text {
        padding: 0 24px 0 !important;
    }

    >>> .v-item-group {
        flex: 1;
    }

    >>> .v-btn-toggle > .v-btn.v-btn {
        flex: 1 !important;
    }

    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
    }
</style>
