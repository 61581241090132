<template>
  <v-card
    class="mx-auto boder-bottom"
    flat
    style="border-radius: 0px;"
    width="100%"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item
      v-ripple="false"
      class="text-left"
      style="padding: 14px 18px !important;"
      @click="onDetailClicked(item)"
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title
          class="d-flex justify-space-between align-start"
        >
          <!-- 업체명 -->
          <div
            class="text-left font__17 black--text font-weight-bold"
            style="white-space: pre-line; word-break: break-word;"
          >
            {{ item.raw.accountName || '-' }}[{{ item.raw.accountId > 0 ? item.raw.accountId : '-' }}]
          </div>
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-row
            class="mx-0 d-flex flex-column font__14"
          >
            <span>{{ '상담일' | translate }} : {{ item.raw.writeDate | date }}
            </span>
            <span>{{ '작업내용' | translate }}:</span>
            <span class="line_2_ellipsis">
              {{ item.raw.content || '-' }}</span>
            <span>{{ '작성일시' | translate }}: {{ item.raw.regDatetime | datetime }}</span>
            <span>{{ '수정일시' | translate }}: {{ item.raw.uptDatetime | datetime }}</span>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>

export default {
  name: "ConsultNoteCard",
  components: {},
  props: {
    item: {type: Object, default: undefined},
  },
  computed: {},
  methods: {
    /**
     * 상세 버튼 클릭시
     * @param
     */
    onDetailClicked(item) {
      this.$emit("click:consult-item", item)
    },
    onReloadClicked() {
      console.log('### this.$emit("update:reload")')
      this.$emit("update:reload")
    },

  }
}
</script>

<style scoped>
ul {
  list-style: none;
  text-align: left;
}

.line_2_ellipsis {
  color: black;
  font-size: 12px;
  white-space: pre-wrap;
  word-break: break-word;
  box-sizing: inherit;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.v-application .subtitle-2 {
  font-size: 0.875rem;
  font-weight: inherit !important;
  letter-spacing: 0.0071428571em !important;
  line-height: 1.375rem;
  font-family: "Roboto", sans-serif !important;
}
</style>
