<template>
  <v-btn
    :loading="loading"
    class="justify-space-between"
    color="primary"
    elevation="0"
    height="62px"
    tile
    width="100%"
    style="background-color: #fff !important;"
    @click="$emit('click')"
  >
    <span>{{ 'A/S 접수하기' | translate }}</span>
    <span>
      <img
        src="@/assets/images/main/svg/acceptAarrow.svg"
        style="padding-top: 2px; width: 8px;"
      ></span>
  </v-btn>
</template>

<script>
    import {PATH} from "@/constants";

    export default {
        name: "ButtonSchedule",
        components: {},
        data() {
            return {
                loading: false,
                raw: {
                    asReadyCnt: 0,
                    asReservedCnt: 0,
                },
            }
        },
        created() {
            // this.processLoadOnce(true);
        },
        mounted() {
            // if (!this.alreadyLoaded) {
            //     this.processLoadOnce(false);
            // }
            //
            // this.alreadyLoaded = false;
        },
        methods: {
            // processLoadOnce(loading) {
            //     this.loadWorkorderSummary(loading);
            //     this.alreadyLoaded = true;
            // },

            async loadWorkorderSummary(loading = true) {
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.WORK_ORDER.SUMMARY)
                        .post()
                        .enqueue();
                    this.raw = response.data;
                } catch (error) {
                    console.error(error);
                }
                this.loading = false;
            },

            refresh() {
                // this.loadWorkorderSummary(false);
            },
        }
    }
</script>

<style scoped>
    .v-application .primary {
        background-color: #fff !important;
        border: 1px solid #aaa !important;
        color: #0C98FE;
    }

    .v-btn__content span:first-child {
        font-size: 1.125rem;
        font-weight: bold;
    }

    .v-btn__content span:last-child {
        font-size: 0.875rem;
    }

    #app .v-application .primary {
        background-color: #fff !important;
        color: #0C98FE !important;
    }
</style>
