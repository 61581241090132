<template>
  <v-card
    class="mx-auto boder-bottom"
    flat
    style="border-radius: 0px;"
    width="100%"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item
      v-ripple="false"
      class="text-left"
      style="padding: 14px 18px !important;"
      @click="onDetailClicked(item)"
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title
          class="d-flex justify-space-between align-start"
        >
          <!-- 업체명 -->
          <div
            class="text-left font__17 black--text font-weight-bold"
            style="white-space: pre-line; word-break: break-word;"
          >
            {{ item.raw.accountName || '-' }}[{{ item.raw.accountId > 0 ? item.raw.accountId : '-' }}]
          </div>
        </v-list-item-title>
        <v-list-item-subtitle class="font__17 black--text font-weight-bold">
          <div style="white-space: pre-wrap !important;">
            <span>{{ item.raw.address }}</span>
          </div>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-row
            class="mx-0 d-flex flex-column font__14"
          >
            <span>{{ '작업일시' | translate }} : {{ item.raw.workDate | date }}
              <span style="color: rgb(12, 152, 254);">{{ item.raw.startTime }}~{{ item.raw.endTime }}</span>
            </span>
            <span>{{ '상태' | translate }} : {{ ( item.raw.workState === 'R' ? '작성중' : '작성완료')| translate }}</span>
            <span>{{ '성명' | translate }} : {{ item.raw.workerName || '-' }}</span>
            <span>{{ '작업유형' | translate }}: {{ item.raw.workTypeName || '-' }}</span>
            <span>{{ '작업내용' | translate }}: {{ item.raw.workContents || '-' }}</span>
            <span>{{ '등록일시' | translate }}: {{ item.raw.regDatetime | datetime }}</span>
            <span>{{ '수정일시' | translate }}: {{ item.raw.uptDatetime | datetime }}</span>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>

    export default {
        name: "WorkRecordItemCard",
        components: {},
        props: {
            item: {type: Object, default: undefined},
        },
        computed: {},
        methods: {
            /**
             * 상세 버튼 클릭시
             * @param work
             */
            onDetailClicked(item) {
                this.$emit("click:item", item)
            },

        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        text-align: left;
    }

    .v-application .subtitle-2 {
        font-size: 0.875rem;
        font-weight: inherit !important;
        letter-spacing: 0.0071428571em !important;
        line-height: 1.375rem;
        font-family: "Roboto", sans-serif !important;
    }
</style>
