<template>
  <v-dialog
    v-model="dialog"
    max-width="340px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <div class="widget_modal_title_wrap">
        <div>{{ '검색 필터' | translate }}</div>
        <v-btn
          :ripple="false"
          icon
          @click="onDialogClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="widget_select_view_wrap">
        <v-row class="mb-2 ma-0">
          <j-period-picker
            :start-label="'시작일' | translate"
            :end-label="'종료일' | translate"
            :date-end.sync="internalSearchOptions.end"
            :date-start.sync="internalSearchOptions.start"
            icon="mdi-calendar"
            class="mx-3"
          />
        </v-row>
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="internalSearchOptions.workState"
            :items="workStateCodeOptions"
            :placeholder="'상태' | translate"
            clearable
            hide-details
            class="pa-0 mx-3"
          />
        </v-col>
        <v-col
          v-if="user.isAdmin"
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="internalSearchOptions.worker"
            :items="staffOptions"
            :placeholder="'작업자' | translate"
            item-text="userFullname"
            item-value="userId"
            clearable
            hide-details
            class="pa-0 mx-3"
          />
        </v-col>
      </div>
      <div
        class="complete_button"
        @click="onClickHandler"
      >
        <button>{{ '설정 완료' | translate }}</button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
    import JPeriodPicker from "@/assets/plugins/mps-vuetify-compat/pickers/JPeriodPicker";
    import VSelectBackguard from "@/components/part/VSelectBackguard";
    import {cloneDeep} from "lodash";
    export default {
        name: "WorkRecordFilterModal",
        components: {JPeriodPicker,VSelectBackguard},
        props: {
            dialog: Boolean,
            searchOptions: undefined,
            staffOptions: undefined,
            workStateCodeOptions: undefined,
        },
        data() {
            return {
              internalSearchOptions: null,
            }
        },
        watch: {
          searchOptions: {
            deep: true,
            immediate: true,
            handler(newValue) {
              this.internalSearchOptions = cloneDeep(newValue)
            }
          },
        },

        methods: {
              onDialogClose() {
                  this.$emit('onDialogClose')
              },
              onClickHandler() {
                 this.$emit('update:searchOptions', this.internalSearchOptions)
                 this.$emit('status-options-items')
                  this.onDialogClose();

              },

          }
    }
</script>

<style scoped>
    * {
        font-family: "Noto Sans KR", sans-serif;
    }

    .v-sheet.v-card {
        padding: 23px 15px;
        text-align: left;
    }

    .widget_modal_title_wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
    }

    .widget_modal_title_wrap div {
        font-size: 1rem;
        color: #333;
        font-weight: 500;
    }

    .widget_modal_info {
        font-weight: normal;
        font-size: 0.75rem;
        color: #8e8e8e;
        margin-bottom: 14px;
    }

    .widget_select_view_wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 18px;
    }

    .widget_select_view_wrap > div {
        width: 68px;
        height: 66px;
    }

    /* 위젯이 선택했을때 적용될 css 입니다.
    선택 시 클래스를 추가해주세요 */
    .active {
        border: 1px solid #0C98FE !important;
        color: #0C98FE !important;
        background-color: rgba(12, 152, 254, 0.07);
        background-image: url(/img/check.svg);
        background-repeat: no-repeat;
        background-position: 93% 8%;
    }

    .widget_select_box {
        color: #878787;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #ddd;
        font-size: 0.875rem;
        margin: 4px;
    }


    .complete_button {
        width: 100%;
        text-align: center;
    }

    .complete_button button {
        width: 100%;
        height: 34px;
        color: white;
        background-color: #0c98fe;
        border-radius: 2px;
        font-size: 0.833rem;
    }

</style>